<template>
    <div class="create">
        <h1>
            Create new project
        </h1>
        {{ currentUser.username }}

        <Spacer :size="20" />

        <form @submit.prevent="create">
            <div class="input-group">
                <input 
                    type="text" 
                    v-model="name"
                    name="name" 
                    id="name"
                    placeholder="Fancy project">
                <label for="name">Project name</label>
            </div>
            <div class="input-group">
                <input
                    type="text"
                    v-model="link"
                    name="link"
                    id="link"
                    placeholder="Link to your project">
                <label for="link">Link</label>
            </div>
            <div class="input-group has-textarea">
                <textarea
                    v-model="description"
                    name="description"
                    id="description"
                    placeholder="Add come details"
                    autocomplete="off"
                    class="resize-textarea"></textarea>
                <label for="description">Description</label>
            </div>

            <Spacer :size="20" />

            <button
                type="submit"
                class="btn primary full-width">
                Create <span v-if="name.length != 0" class="name">"{{ name }}"</span>
            </button>

            <div class="notification-wrapper" v-if="message.text">
                <Spacer :size="20" />
                <InlineNotification 
                    :success="message.success">
                    {{ message.text }}
                </InlineNotification>
            </div>
        </form>
    </div>
</template>

<script>
import resizeTextareas from "@/js/resize-textareas"
import Spacer from "@/components/spacer"
import { mapGetters } from "vuex"
import ProjectService from "@/services/ProjectService"
import InlineNotification from "@/components/notifications/inline-notification"

export default {
    name: 'add',
    data() {
        return {
            name: '',
            description: '',
            link: '',
            message: {
                success: false,
                message: ''
            },
            isLoading: false
        }
    },
    mounted() {
        resizeTextareas.init()

        console.log(this.$store.getters.currentUser.username)
        console.log(this.currentUser.username)
    },
    computed: {
        ...mapGetters([ 'isAuthenticated', 'currentUser' ])
    },
    async created() {
        if(!this.$store.getters.isAuthenticated) {
            this.$router.push({ name: 'login' })
        }
    },
    methods: {
        async create() {
            try {
                this.isLoading = true

                const details = {
                    name: this.name,
                    description: this.description,
                    link: this.link,
                    user: this.$store.getters.currentUser.username
                }

                const response = await ProjectService.create(details)
                this.message = {
                    text: response.message,
                    success: response.success
                }
                this.isLoading = false
            } catch(error) {
                this.message = {
                    text: error.message,
                    success: false
                }
                this.isLoading = false
            }
        }
    },
    components: {
        Spacer,
        InlineNotification
    }
}
</script>

<style lang="scss" scoped>
button[type=submit] {
    .name {
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:hover {
        .name {
            opacity: 1;
        }
    }
}
</style>