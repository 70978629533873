<template>
    <div class="spacer" :style="'height:' + size + 'px'"></div>
</template>

<script>
export default {
    name: "Spacer",
    props: ["size"]
}
</script>

<style>

</style>