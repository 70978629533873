<template>
    <div class="inline-notification" :class="{ 'has-emoji': emoji, 'is-success': success, 'is-error': !success }">
        <h4 class="message">
            <slot></slot>
        </h4>

        <div class="emoji" v-if="emoji">{{ emoji }}</div>
    </div>
</template>

<script>
export default {
    name: 'InlineNotification',
    props: ['emoji', 'success']
}
</script>

<style lang="scss" scoped>
$success: #4BB543;
$error: #FF3333;

.inline-notification {
    padding: 11px 15px 12px 15px;
    line-height: 1;
    letter-spacing: 0.4px;
    border-radius: $borderRadiusInner;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.is-success {
        background: lighten($success, 50%);
        border: 1px solid lighten($success, 40%);
        color: $success;
    }
    &.is-error {
        background: lighten($error, 37%);
        border: 1px solid lighten($error, 30%);
        color: $error;
    }

    &.has-emoji {
        padding-right: 10px;
    }
}
</style>